<!-- 商品行标签倒计时，目前仅支持最简单的倒计时场景 -->
<template>
  <div
    v-show="showCountDown"
    class="new-down-time"
  >
    <div class="time-content">
      <span
        class="count-number count-down-hours"
        :style="{ fontSize: fontSize }"
      >{{ diffHours }}</span>
      <Icon name="sui_icon_colon_2px" width="2px" />
      <span
        class="count-number count-down-mins"
        :style="{ fontSize: fontSize }"
      >{{ diffMinutes }}</span>
      <Icon name="sui_icon_colon_2px" width="2px" />
      <span
        class="count-number count-down-mins"
        :style="{ fontSize: fontSize }"
      >{{ diffSeconds }}</span>
    </div>
  </div>
</template>
<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { mapState } from 'vuex'
export default {
  name: 'CartTagsCountDown',
  components: {
    Icon,
  },
  props: {
    timeStamp: {
      type: Number,
      required: false,
      default: 0
    },
    fontSize: {
      type: String,
      default: '12px'
    },
    // 倒计时归零时，不隐藏倒计时
    notHide: Boolean,
    // 倒计时归零时，不刷新页面
    notReload: Boolean
  },
  data: function() {
    return {
      diffDays: '',
      diffHours: '',
      diffMinutes: '',
      diffSeconds: '',
      timer: null,
      showCountDown: true
    }
  },
  computed: {
    ...mapState('cart', ['cartLeave']),
  },
  watch: {
    // 到手价倒计时需求中，timeStamp会随着勾选商品变化，此时需要重新计算倒计时
    timeStamp() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    if(this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }   
  },
  activated() {
    this.init()
  },
  deactivated() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }   
  },
  methods: {
    init() {
      const countDownData = this.countDownTime({ endTimestamp: this.timeStamp })
      this.diffDays = 0 
      this.diffHours = +countDownData.diffHours + (+countDownData.diffDays * 24)
      this.diffMinutes = +countDownData.diffMinutes
      this.diffSeconds = +countDownData.diffSeconds
      if (countDownData.diffTime <= 0) { // init found count down is <= 0, do not render countdown
        this.showCountDown = false
      }
      this.setTimer()
    },
    setTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.cartLeave) return // deactivated 钩子过晚
          if (this.diffSeconds - 1 >= 0) {
            this.diffSeconds = this.diffSeconds - 1
          } else {
            if (this.diffMinutes - 1 >= 0) {
              this.diffMinutes = this.diffMinutes - 1
              this.diffSeconds = 59
            } else {
              if (this.diffHours - 1 >= 0) {
                this.diffHours = this.diffHours - 1
                this.diffMinutes = 59
                this.diffSeconds = 59
              } else {
                if (this.diffDays - 1 >= 0) {
                  this.diffDays = this.diffDays - 1
                  this.diffHours = 24
                  this.diffMinutes = 59
                  this.diffSeconds = 59
                } else {
                  clearInterval(this.timer)
                  if (!this.notHide) this.showCountDown = false
                  if (!this.notReload) window.location.reload(true)
                  this.$emit('countDownEnd')
                }
              }
            }
          }
        }, 1000)
      }
    },

    /**
     * 计算倒计时
     */
    countDownTime({ endTimestamp = 0 }) {
      let end = endTimestamp || 0
      if(end){
        end = (end + '').length >= 13 ? end : end * 1000
      }
      const now = (new Date()).getTime()
      const diffTime = end - now
      const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24) // 相差天数
      const diffHours = Math.floor(diffTime / 1000 / 60 / 60 - diffDays * 24) // 相差小时
      const diffMinutes = Math.floor(diffTime / 1000 / 60 - diffDays * 24 * 60 - diffHours * 60) // 相差分钟
      const diffSeconds = Math.floor(diffTime / 1000 - diffDays * 24 * 60 * 60 - diffHours * 60 * 60 - diffMinutes * 60) // 相差秒数

      return {
        diffTime,
        diffDays,
        diffHours,
        diffMinutes,
        diffSeconds
      }
    },
  },
}
</script>
<style lang="less" scoped>
.count-down-time {
  font-weight: normal;
  .font-dpr(24px);
  color: @sui_color_highlight;
  .time-content {
    font-weight: bold;
  }
  span.count-number {
    vertical-align: baseline!important; /* stylelint-disable-line declaration-no-important */
  }
}
.new-down-time {
  .count-number {
    background: none;
    padding: 0 4/75rem,
  }
}
.time-content {
  /* rtl:begin:ignore */
  direction: ltr;
  display: inline-flex;
  align-items: center;
}
</style>
