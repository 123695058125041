var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.SLabel,{class:[
    'quick-ship-tag',
    {
      'quick-ship-tag_new': _setup.quickShipTag.isShowNewStyle,
      'new-cartrow-vision': _vm.isNewCartrowVision,
      'quick-ship-tag_no_bg': _setup.quickShipTag.noLableStyle
    }
  ],attrs:{"type":"success"}},[_c('span',{staticClass:"quick-ship-tag__title"},[(_setup.quickShipTag.isShowNewStyle)?_c(_setup.Icon,{staticClass:"quick-ship-tag__icon",attrs:{"name":"sui_icon_qucikship_flat_24px","size":_setup.IconStyle.size,"color":_setup.IconStyle.color}}):_vm._e(),_vm._v(" "),_c('span',{class:[
        'quick-ship-tag__text',
        {
          'quick-ship-tag__text_new': _setup.quickShipTag.isShowNewStyle,
        }
      ],style:(_setup.quickShipTag.themVarText)},[_vm._v(_vm._s(_setup.quickShipContent.tag))])],1),_vm._v(" "),(_setup.quickShipTag.isShowNewStyle && !_setup.quickShipTag?.qsTimelinessInfo?.isLocationTop && _setup.quickShipContent.tag_des)?[_c('span',{staticClass:"divider"}),_vm._v(" "),_c('span',{staticClass:"quick-ship-tag__tip"},[_c('span',{staticClass:"quick-ship-tip__text",domProps:{"innerHTML":_vm._s(_setup.handleTip(_setup.quickShipContent.tag_des))}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }