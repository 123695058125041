var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['cart-item__tag-bar', { 'new-cartrow-vision': _vm.isNewCartrowVision }]},[(_vm.showSfsTag)?_c('SFSTag',{staticClass:"common_tag",attrs:{"language":_vm.language,"item":_vm.item,"showExtendText":_vm.sfsVisualAbt.visual_benefit_point,"is-new-cartrow-vision":_vm.isNewCartrowVision}}):_vm._e(),_vm._v(" "),(_vm.showQuickShip)?_c('QuickShipTag',{staticClass:"common_tag",attrs:{"cart-qs-tag-info":_vm.cartQsTagInfo,"page":_vm.page,"is-new-cartrow-vision":_vm.isNewCartrowVision,"mall-code":_vm.item.mall_code,"current-item-no":_vm.item.id,"qs-mall-list":_vm.cartQsTagInfo.qsMallList}}):_vm._e(),_vm._v(" "),(_vm.showFreeShipping)?_c('span',{staticClass:"common_tag free-shipping-tag"},[_vm._v("\n    "+_vm._s(_vm.language.SHEIN_KEY_PWA_25453)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.showEvoluShein)?_c('EvoluTag',{staticClass:"common_tag",attrs:{"is-new-cartrow-vision":_vm.isNewCartrowVision}}):_vm._e(),_vm._v(" "),_c('VoucherTag',{staticClass:"common_tag",attrs:{"item":_vm.item,"language":_vm.language,"disable-click":_vm.batchActive,"page":_vm.page}}),_vm._v(" "),(_vm.showPromotionTag)?[(![32].includes(_vm.promotionTag.typeId))?_c('span',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.analysisData('1-8-3-14')),expression:"analysisData('1-8-3-14')"},{name:"expose",rawName:"v-expose",value:(_vm.analysisData('1-8-3-13')),expression:"analysisData('1-8-3-13')"}],staticClass:"common_tag promotion-tag",class:{
        'block': _vm.isBlock,
        'have-time-tag': _vm.timeStamp,
        'flash': _vm.timeStamp && _vm.normalFlash,
        'quick-ship__tag': _vm.showFreeShipping,
        'free-gift': _vm.promotionTag.typeId == 4,
        'flash__tag': _vm.normalFlash,
      },attrs:{"da-event-expose":!_vm.batchActive && _vm.page == 'cart' && [3, 10].includes(_vm.promotionTag.typeId) ? '1-8-5-1' : '',"data-typeId":_vm.promotionTag.typeId,"data-isFullPromotion":0,"data-activity-id":_vm.promotionTag.promotionId,"data-goods-sn":_vm.item.product.goods_sn,"data-is-member-gift":+_vm.isSheinClubGiftTag},on:{"click":_vm.jump}},[_c('span',{staticClass:"tag-content",staticStyle:{"overflow":"hidden"},attrs:{"da-event-expose":_vm.exposePromoTagEventId,"da-event-click":_vm.clickPromoTagEventId,"data-is_flashsale":_vm.item.flashType,"data-goods_id":_vm.item.product.goods_id,"data-is_insured_goods":_vm.item.product.insurable,"data-page":_vm.page}},[_c('s-label',{staticClass:"product-tag-content",class:{
            'has-time': _vm.timeStamp,
            'flash-tag': _vm.normalFlash,
            'club-color': _vm.isSheinClubGiftTag,
          },attrs:{"type":"promo"}},[_c('span',{staticClass:"tag-text"},[_vm._v("\n            "+_vm._s(_vm.promotionTag.typeIdText)+" "+_vm._s(_vm.promotionTag.canJump && !_vm.timeStamp && !_vm.batchActive ? ' >' : '')+"\n          ")]),_vm._v(" "),(_vm.page == 'cart' && _vm.promotionTag.tipText)?_c('sui_icon_info_12px_2',{staticClass:"tip-icon",attrs:{"size":"12px","color":"#959595"}}):_vm._e()],1),_vm._v(" "),(_vm.timeStamp)?_c('span',{staticClass:"tag-time"},[_c('span',{class:[
              'tag-time-split',
              _vm.isSheinClubGiftTag && 'club-color2',
            ]},[_vm._v("|")]),_vm._v(" "),_c('ClientOnly',[(_vm.showDate)?[_c('span',{class:[
                  'end-in-time',
                  _vm.isSheinClubGiftTag && 'club-color'
                ]},[_vm._v("\n                "+_vm._s(_vm.template(_vm.dateStamp, _vm.language.SHEIN_KEY_PWA_31168))+"\n              ")])]:[(_vm.isNewCartrowVision)?_c('CartTagsCountDown',{class:[
                  'cart-tag-time',
                  _vm.isSheinClubGiftTag && 'club-color'
                ],attrs:{"font-size":"10px","not-hide":"","not-reload":"","time-stamp":_vm.timeStamp}}):_c('count-down-time',{class:[
                  'cart-tag-time',
                  _vm.isSheinClubGiftTag && 'club-color'
                ],attrs:{"time-stamp":_vm.timeStamp,"font-size":"10px"}})]],2)],1):_vm._e()],1),_vm._v(" "),_c('span',{class:{ 'club-color': _vm.isSheinClubGiftTag, },staticStyle:{"color":"#767676"}},[(_vm.timeStamp && !_vm.batchActive && _vm.promotionTag.canJump)?_c('sui_icon_more_right_12px',{staticClass:"tag-right-icon",attrs:{"size":"12px","is-rotate":_vm.locals.GB_cssRight}}):_vm._e()],1)]):_c('div',{class:_vm.isNewCartrowVision ? 'easy-coupon-wrap' : 'common_tag',attrs:{"da-event-expose":!_vm.batchActive && _vm.page == 'cart' ? '1-8-5-1' : '',"data-typeId":_vm.promotionTag.typeId,"data-isFullPromotion":0,"data-activity-id":_vm.promotionTag.promotionId,"data-goods-sn":_vm.item.product.goods_sn,"data-is-member-gift":+_vm.isSheinClubGiftTag}},[_c('EasyCoupon',{class:_vm.isNewCartrowVision ? 'common_tag' : '',attrs:{"coupon-code":_vm.promotionTag.typeIdText,"is-new-style":true}})],1)]:_vm._e(),_vm._v(" "),_vm._t("checkout-tag"),_vm._v(" "),(_vm.showTip)?_c('s-dialog',{attrs:{"visible":_vm.showTip,"append-to-body":true},on:{"update:visible":function($event){_vm.showTip=$event}}},[_c('template',{slot:"title"},[_vm._v("\n      "+_vm._s(_vm.promotionTag.tipText)+"\n    ")]),_vm._v(" "),_c('template',{slot:"footer"},[_c('s-button',{attrs:{"type":['H72PX','primary'],"width":"100%"},on:{"click":function($event){_vm.showTip=false}}},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_15312)+"\n      ")])],1)],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }